import {Box, Typography} from "@mui/material";


function NotFoundPage() {
    return(
        <Box textAlign={'center'} justifyContent={'center'}>
            <Typography variant={'h4'} color={"white"}>
                404: Redirecting...
            </Typography>
        </Box>
    )
}

export default NotFoundPage
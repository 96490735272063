// react core
import React from 'react';
import { useState} from "react";
// local files
import SelectionMenu from "../modules/SelectionMenu";
import AdditionalComments from "../modules/AdditionalComments";
import DispatchTPForm from "./DispatchTPForm";
import GetProvider from "../context/GetProvider";
import HardCostList from "./HardCostList";
import StatusAlert from "../modules/StatusAlert";
import DispatchMethods from "./DispatchMethods";
// 3rd party modules
import {Typography, TextField, Stack, Container} from "@mui/material";


export default function TruepicLayout() {

    const [selectedItems, setSelectedItems] = useState([])
    const [comments, setComments] = useState("")
    const [inspectorFields, setInspectorFields] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        useEmail: false,
        usePhone: true
    })
    const [isEmailEnabled, setIsEmailEnabled] = useState(false)
    const [isPhoneEnabled, setIsPhoneEnabled] = useState(false)

    return(
        <Stack
            direction="column"
            alignItems="center"
            rowSpacing={1}
            spacing={1}
        >
            <Typography
                variant={"caption"}
                align={"center"}
                color={"white"}
            >
                Please select a project and at least one inspection item:
            </Typography>
            <GetProvider>
                <SelectionMenu
                    title={"Project"}
                    type={"project"}
                />
                    <Container maxWidth={"sm"}>
                        <Stack
                            direction={"row"}
                            spacing={1}
                        >
                            <TextField
                                required
                                value={inspectorFields.firstName}
                                autoComplete={"off"}
                                label={"First Name"}
                                id="fname"
                                fullWidth
                                onChange={(event) => {setInspectorFields(prevState => {return {...prevState, firstName: event.target.value}})}}
                            />
                            <TextField
                                required
                                value={inspectorFields.lastName}
                                autoComplete={"off"}
                                label={"Last Name"}
                                id="lname"
                                fullWidth
                                onChange={(event) => {setInspectorFields(prevState => {return {...prevState, lastName: event.target.value}})}}
                            />
                        </Stack>
                    </Container>
                    <Container maxWidth={"sm"}>
                        <TextField
                            required
                            value={inspectorFields.phone}
                            autoComplete={"off"}
                            label={"Phone Number"}
                            id="phone"
                            fullWidth
                            onChange={(event) => {setInspectorFields(prevState => {return {...prevState, phone: event.target.value}})}}
                            variant={"outlined"}
                        />
                    </Container>
                    <Container maxWidth={"sm"}>
                        <TextField
                            value={inspectorFields.email}
                            autoComplete={"off"}
                            label={"Email"}
                            id="email"
                            fullWidth
                            onChange={(event) => {setInspectorFields(prevState => {return {...prevState, email: event.target.value}})}}
                        />
                    </Container>
                    <HardCostList
                        selected={{selectedItems, setSelectedItems}}
                    />
                    <AdditionalComments
                        label={"Custom Text Message"}
                        placeholder={"Add an additional information to the custom text message"}
                        setComments={setComments}
                    />
                    <DispatchMethods
                        sendMethods={{isEmailEnabled, isPhoneEnabled}}
                        setSendMethods={{setIsEmailEnabled, setIsPhoneEnabled}}
                    />
                    <DispatchTPForm
                        setInspectorFields={setInspectorFields}
                        inspectorFields={inspectorFields}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        sendMethods={{isEmailEnabled, isPhoneEnabled}}
                        setSendMethods={{setIsEmailEnabled, setIsPhoneEnabled}}
                        comments={comments}
                    />
                    <StatusAlert/>
            </GetProvider>
        </Stack>
    )
}
// react core
import React from "react";
// 3rd party libraries
import { Box, FormControlLabel, Checkbox } from "@mui/material";


export default function DispatchMethods(props) {

    function handleEmail() {
        props.setSendMethods.setIsEmailEnabled(prevState => (!prevState))
    }

    function handlePhone() {
        props.setSendMethods.setIsPhoneEnabled(prevState => (!prevState))
    }

    return (
        <Box
            xs={{
                display: "grid",
                gridTemplateRows: "repeat(2, 1fr)"
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.sendMethods.isPhoneEnabled}
                        onChange={handlePhone}
                    />
                }
                label="Phone"
                sx={{
                    color: "white"
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.sendMethods.isEmailEnabled}
                        onChange={handleEmail}
                    />
                }
                label="Email"
                sx={{
                    color: "white"
                }}
            />
        </Box>
    )
}

// react core
import React from 'react';
import {useState, useContext, useEffect, useRef} from "react";
// local files
import { DataContext } from "../context/GetProvider";
// 3rd party modules
import { Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

export default function DispatchDMForm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const {
        stakeholders,
        SOV,
        allProjects,
        setAlert,
        isDisabled,
        setIsDisabled,
        selectedProject,
        selectedDevice,
        sessionToken
    } = useContext(DataContext)
    const [body, setBody] = useState({})
    const isNewBody = useRef(false)
    const NAMESPACE = "3f6ebdc0-192c-013c-3b2b-1623dfb9e10d"
    const DISPATCH_URL = 'https://qdproxy.services/dm/dispatch'

    // assemble form when dispatch button is clicked
    function handleClick() {
        setIsLoading(true)
        setIsDisabled(true)
        isNewBody.current = false
        assembleForm()
    }

    // dispatch form after the body has been set
    useEffect(() => {

        if (isNewBody.current) {
            const dispatch = dispatchForm(selectedDevice, DISPATCH_URL)
            Promise.all([
                dispatch
            ])
                .then(() => {
                    setAlert({
                        message: `Successfully dispatched ${body.form_name}`,
                        severity: "success"
                    })
                })
                .catch((error) => {
                    setAlert({
                        message: error.message,
                        severity: "error"
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [body])


    // assemble the dispatch form using provider state
    function assembleForm() {

        // assemble stakeholders
        try {
            // throw error is either project or device is not selected
            if (!selectedDevice || !selectedProject) {
                throw new Error("Please select a project and inspector before dispatching.")
            }

            // map stakeholders
            // note: several stakeholders may exist for the same role type
            const allStakeholders = stakeholders.data.map((stakeholder) => {
                switch (stakeholder.role) {
                    case 'LENDER':
                        return ["lender", stakeholder.vendor.name]
                    case 'BORROWER':
                        return ["borrower", stakeholder.vendor.name]
                    case 'CONTRACTOR':
                        return ["gc_name", stakeholder.vendor.name]
                    default:
                        return ["none", "none"]
                }
            })

            // create a unique set of stakeholders
            let unique = [...new Set(allStakeholders)]
            // convert array to object
            unique = Object.fromEntries(allStakeholders)

            // throw error if any required stakeholder is missing
            if (!unique.borrower) {throw new Error("Missing Borrower stakeholder in Rabbet. Please update before dispatching.")}
            if (!unique.lender) {throw new Error("Missing Lender stakeholder in Rabbet. Please update before dispatching.")}
            if (!unique.gc_name) {throw new Error("Missing General Contractor stakeholder in Rabbet. Please update before dispatching.")}

            // find the selected project from all projects
            const projectDetails = allProjects.data.find(project => project.id === selectedProject)

            // map line items
            let lineItems = []
            try {
                lineItems = SOV.data[0].lineItems.map(item => {

                    // calculate the %-requested to date and get the item name
                    let percentToDate = (item.requestedToDateAmount / item.budgetAmount) * 100;
                    percentToDate = Math.ceil(percentToDate);
                    percentToDate = percentToDate.toFixed(0);

                    return {line_item: `${item.name} | ${percentToDate}%`}
                })
            }
            catch(error) {
                // Do nothing if SOV is empty. Warning has already been delivered
            }

            // set the form body
            setBody({
                form_namespace: `http://www.devicemagic.com/xforms/${NAMESPACE}`,
                form_name: unique.borrower,
                form_description: `Site inspection form for ${unique.borrower} funded by ${unique.lender}`,
                payload: {
                    proj_details: {
                        ...unique,
                        pm_name: projectDetails.customFields.projectManager,
                        pm_phone: projectDetails.customFields.projectManagerPhone,
                        proj_addr: `${projectDetails.streetAddress}, ${projectDetails.city}, ${projectDetails.state} ${projectDetails.zip}`,
                        orig_compl_date: projectDetails.customFields.origdateofcomp || 'To be determined.',
                        rev_compl_date: projectDetails.customFields.revdateofcomp || projectDetails.customFields.origdateofcomp || 'To be determined.',
                        proj_desc: projectDetails.customFields.projectDescription
                    },
                    draw_details_group: {
                        req_line_items: lineItems
                    },
                    disp_notes: props.comments
                }
            })
            isNewBody.current = true
        }
        catch(error) {
            setIsLoading(false)
            setIsDisabled(false)
            setAlert({
                message: error.message,
                severity: "error"
            })
        }
    }

    // dispatch form
    async function dispatchForm(deviceID, url) {
        try {
            // convert to proper string form
            const dispatchBody = JSON.stringify(body);

            // dispatch form
            const options = {
                method: "POST",
                body: dispatchBody,
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            }

            const response = await fetch(`${url}/${deviceID}`, options)

            if (response.ok) {
                return(response.json())
            }
            else {
                throw new Error("Unable to dispatch form. Please contact admin for help.")
            }
        }
        catch(error) {
            throw error
        }
    }

    return(
        <LoadingButton
            onClick={handleClick}
            endIcon={<Send/>}
            loading={isLoading}
            disabled={isDisabled}
            loadingPosition="end"
            variant="contained"
            sx={{
                marginTop: 1,
                width: 175
            }}
        >
            Dispatch
        </LoadingButton>
    )
}
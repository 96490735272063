// react core
import React from 'react';
import { useContext} from "react";
// local files
import {DataContext} from "../context/GetProvider";
// 3rd party libraries
import {Container, TextField} from "@mui/material";


export default function AdditionalComments(props) {

    const handleTyping = (event) => {
        props.setComments(event.target.value);
    };
    const { isDisabled } = useContext(DataContext)

    return(
        <Container maxWidth="sm">
            <TextField
                id="outlined-multiline-static"
                label={props.label}
                multiline
                rows={8}
                defaultValue={''}
                placeholder={props.placeholder}
                onChange={handleTyping}
                disabled={isDisabled}
                fullWidth
            />
        </Container>
    )
}
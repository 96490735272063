// react core
import React from 'react';
import { useState, useEffect, useContext } from "react";
// local files
import { DataContext } from "../context/GetProvider";
// 3rd party modules
import {InputLabel, MenuItem, FormControl, Select, Container} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            width: 375,
        },
    },
}

export default function SelectionMenu(props) {

    const [menuItems, setMenuItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('')
    const fetchedData = useContext(DataContext)

    // handle a new selection from the list
    const handleSelection = (event) => {
        if (props.type === "project") {
            fetchedData.setSelectedProject(event.target.value)
        }
        else {
            fetchedData.setSelectedDevice(event.target.value)
        }

        setSelectedItem(event.target.value)
    }

    // extract list values and create lists
    useEffect(() => {

        // device list
        if (fetchedData.allDevices && props.type === "device") {
            const devices = fetchedData.allDevices.devices
            setMenuItems(devices.map(device => {
                return(
                    <MenuItem
                        value={device.identifier}
                        key={device.identifier}
                    >
                        {device.owner}
                    </MenuItem>)
            }))
        }

        // project list
        if (fetchedData.allProjects && props.type === "project") {
            const projects = fetchedData.allProjects.data
            setMenuItems(projects.map(project => {
                return(
                    <MenuItem
                        value={project.id}
                        key={project.id}
                    >
                        {project.name}
                    </MenuItem>)
            }))
        }
    }, [fetchedData.allDevices, fetchedData.allProjects]);

    return (
        <Container maxWidth="sm">
            <FormControl
                size="medium"
                fullWidth
                required={true}
            >
                <InputLabel>{props.title}</InputLabel>
                <Select
                    value={selectedItem}
                    onChange={handleSelection}
                    label={props.title}
                    disabled={fetchedData.isDisabled}
                    MenuProps={MenuProps}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </Container>
    )
}
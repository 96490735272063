
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {

    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = window.location.origin

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    // return null if any of the required parameters are missing
    if (!(domain && clientId && redirectUri)) {
        //FIXME: add better error handling here
        console.log("Error: cannot find domain, clientID and/or redirectUri")
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: `https://dev-kv8zr3xua4cowmer.us.auth0.com/api/v2/`,
                scope: `read:current_user update:current_user_metadata`
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
// react core
import React from 'react';
import PropTypes from 'prop-types';
// 3rd party
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from "@mui/material";
// local files
import DeviceMagicLayout from "../device-magic/DeviceMagicLayout"
import TruepicLayout from "../truepic/TruepicLayout"
import DispatchesLayout from "../dispatches-list/DispatchesLayout";
import LogoutBar from "./LogoutBar";
import {useAuth0} from "@auth0/auth0-react";
import {LoadingPage} from "../pages/LoadingPage";
import CoverSheetLayout from "../cover-sheet/CoverSheetLayout";

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const handleLogin = async(loginWithRedirect) => {
    // default return to '/' URL
    await loginWithRedirect()
        .then(response => {
            console.log(response)
        })
}

export default function ToggleServices(props) {

    // auth0 react hook
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

    // check if the user is authenticated, if not, execute login method
    if (!isAuthenticated && !isLoading) {
        handleLogin(loginWithRedirect)
    }

    // set default tab index to 0
    const [value, setValue] = React.useState(0);

    // handle switching between tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // conditionally render the loading page if the user is not logged in
    if (isAuthenticated) {
        return (
            <Container>
                <Box
                    sx={{
                        paddingTop: 2,
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="dispatcher tool tabs"
                    >
                        <Tab label="Device Magic" {...a11yProps(0)}/>
                        <Tab label="Truepic" {...a11yProps(1)} />
                        <Tab label="Dispatches" {...a11yProps(2)} />
                        <Tab label="Cover Sheet" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel
                    value={value}
                    index={0}
                >
                    <DeviceMagicLayout/>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                >
                    <TruepicLayout/>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={2}
                >
                    <DispatchesLayout/>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={3}
                >
                    <CoverSheetLayout/>
                </TabPanel>
                <LogoutBar />
            </Container>
        )
    }
    else {
        return ( <LoadingPage />)
    }
}
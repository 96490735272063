// react core
import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
// 3rd party modules
import { Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
// local files
import { DataContext } from "../context/GetProvider";

export default function DispatchTPForm(props) {

    const [isLoading, setIsLoading] = useState(false)
    const isBodyReady = useRef(false)
    const [body, setBody] = useState({})
    const {
        allProjects,
        stakeholders,
        setAlert,
        isDisabled,
        setIsDisabled,
        selectedProject,
        sessionToken
    } = useContext(DataContext)
    const DISPATCH_URL = "https://qdproxy.services/truepic/dispatch"


    function setUIFields() {
        isBodyReady.current = false
        // map stakeholders
        // note: several stakeholders may exist for the same role type
        try {
            const allStakeholders = stakeholders.data.map((stakeholder) => {
                switch (stakeholder.role) {
                    case 'BORROWER':
                        return ["borrower", stakeholder.vendor.name]
                    case 'INSPECTOR':
                        return ["inspector", stakeholder.member]
                    default:
                        return ["none", "none"]
                }
            })

            // create a unique set of stakeholders
            let unique = [...new Set(allStakeholders)]

            // create object from mapped data
            unique = Object.fromEntries(allStakeholders)

            // throw an error if required stakeholder are missing
            if (!unique.borrower) {throw new Error("Missing Borrower stakeholder in Rabbet. Dispatch will fail until updated.")}
            if (!unique.inspector) {throw new Error("Missing Inspector stakeholder in Rabbet.")}

            // separate full name into first and last
            const separateName = unique.inspector.name.split(" ")

            // set ui fields
            props.setInspectorFields({
                firstName: separateName[0],
                lastName: separateName[1],
                email: unique.inspector.email,
                phone: unique.inspector.phone
            })

            // set enabled dispatch methods
            if (unique.inspector.email) {
                props.setSendMethods.setIsEmailEnabled(true)
            }
            if (unique.inspector.phone) {
                props.setSendMethods.setIsPhoneEnabled(true)
            }

        }
        catch (error) {
            setAlert({
                message: error.message,
                severity: "error"
            })
        }
    }


    function handleClick() {

        try {
            // throw error if no project is selected
            if (!selectedProject) {throw new Error("Please select a project and at least one inspection item before dispatching.")}
            setIsLoading(true)
            setIsDisabled(true)
            assembleForm()
            isBodyReady.current = true
        }
        catch (error) {
            setAlert({
                message: error.message,
                severity: "error"
            })
            setIsLoading(false)
        }
    }


    function clearSendMethods() {
        props.setSendMethods.setIsPhoneEnabled(false)
        props.setSendMethods.setIsEmailEnabled(false)
    }


    function clearInspectorFields() {
        props.setInspectorFields({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        })
    }


    function clearInspectionItems() {
        props.setSelectedItems([])
    }


    // clear previous states and set UI fields when stakeholders changes
    useEffect(() => {
        if (stakeholders) {
            clearInspectorFields()
            clearInspectionItems()
            clearSendMethods()
            setUIFields()
        }
    }, [stakeholders])


    // toggle send methods when a manual change to the UI fields occur
    useEffect(() => {

        // toggle email send method
        if (props.inspectorFields.email) {
            props.setSendMethods.setIsEmailEnabled(true)
        }
        else {
            props.setSendMethods.setIsEmailEnabled(false)
        }

        // toggle phone send method
        if (props.inspectorFields.phone) {
            props.setSendMethods.setIsPhoneEnabled(true)
        }
        else {
            props.setSendMethods.setIsPhoneEnabled(false)
        }
    }, [props.inspectorFields.email, props.inspectorFields.phone])


    // dispatch form after body is ready and updated
    useEffect(() => {

        if(isBodyReady.current) {
            // dispatch form
            const options = {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                }
            }

            // send dispatch
            fetch(DISPATCH_URL, options)
                .then(response => {
                    // check response
                    if (response.ok) {
                        setAlert({
                            message: `Successfully dispatched ${body.consumer_unique_id}`,
                            severity: "success"
                        })
                    }
                    else {
                        throw new Error("Unable to dispatch form. Please check information in Rabbet or contact admin for help.")
                    }
                })
                .catch(error => {
                    setAlert({
                        message: error.message,
                        severity: "error"
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

    }, [isBodyReady, body])


    // assemble form body
    function assembleForm() {
        // create inspection items
        if (props.selectedItems.length < 1) {
            throw new Error("Please select at least one inspection item before dispatching.")
        }
        const inspectionItems = props.selectedItems.map(item => {
            return {
                type: "IMAGE",
                name: item,
                is_required: false,
                question: {
                    type: "MULTIPLE_CHOICE",
                    options: {
                        choices: [
                            {
                                name: item,
                                allow_custom: true
                            }
                        ],
                        allow_multiple: true
                    }
                }
            }
        })

        // get selected project details
        const projectDetails = allProjects.data.find(project => project.id === selectedProject)

        // set form body
        setBody({
            consumer_unique_id: projectDetails.name,
            consumer_first_name: props.inspectorFields.firstName,
            consumer_last_name: props.inspectorFields.lastName,
            customer_setup_id: 2390,
            event_type_id: 3606,
            consumer_phone_number: props.inspectorFields.phone,
            consumer_email_address: props.inspectorFields.email,
            consumer_address: `${projectDetails.streetAddress}, ${projectDetails.city}, ${projectDetails.state} ${projectDetails.zip}`,
            message_supplement: props.comments,
            include_list: true,
            list: {
                items: inspectionItems
            },
            send_methods: {
                text: props.sendMethods.isPhoneEnabled,
                email: props.sendMethods.isEmailEnabled
            }
        })
    }


    return(
        <LoadingButton
            onClick={handleClick}
            endIcon={<Send/>}
            loading={isLoading}
            disabled={isDisabled}
            loadingPosition="end"
            variant="contained"
            sx={{
                width: 175
            }}
        >
            Dispatch
        </LoadingButton>
    )
}
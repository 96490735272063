// react core
import React, {useState} from "react";
// 3rd party
import {Container, FormControl, FormLabel, Stack, Typography} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// local files
import GetProvider from "../context/GetProvider";
import SelectionMenu from "../modules/SelectionMenu";
import StatusAlert from "../modules/StatusAlert";
import CreateCoverSheet from "./CreateCoverSheet";

const CoverSheetLayout = () => {

    const [observations, setObservations] = useState('')
    const [inspectionDate, setInspectionDate] = useState(null)
    const [wasStaffing, setWasStaffing] = useState(true)

    // method for toggling staff boolean
    const toggleStaff = () => {
        setWasStaffing(prevState => !prevState)
    }

    return (
        <Stack
            direction="column"
            alignItems="center"
            rowSpacing={1}
            spacing={1}
        >
            <Typography
                variant="caption"
                color="white"
                align="center"
            >
                Please select a project to generate a cover sheet:
            </Typography>
            <GetProvider>
                <SelectionMenu
                    title={"Project"}
                    type={"project"}
                />
                <Container maxWidth={"sm"}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{pt: 0.3}}>
                            <DatePicker
                                label="Inspection Date"
                                value={inspectionDate}
                                onChange={(newValue) => setInspectionDate(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <FormControl
                        variant={"outlined"}
                        sx={{pr: 1}}
                    >
                        <FormLabel>Subcontractor staffing?</FormLabel>
                        <RadioGroup
                            aria-labelledby="sub-staffing-radio-group"
                            defaultValue={true}
                            name="radio-buttons-group"
                            row={true}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio onChange={toggleStaff} />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio onChange={toggleStaff}/>}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>
                </Container>
                <CreateCoverSheet
                    observations={observations}
                    wasStaffing={wasStaffing}
                    inspectionDate={inspectionDate}
                />
                <StatusAlert/>
            </GetProvider>
        </Stack>
    )

}

export default CoverSheetLayout
// react core
import React, {useContext, useState} from "react";
// 3rd party
import LoadingButton from "@mui/lab/LoadingButton";
import {Send} from "@mui/icons-material";
import PizZip from 'pizzip'
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

// local files
import {DataContext} from "../context/GetProvider";

const CreateCoverSheet = (props) => {

    // loading state, used to disable UI when generating cover sheet
    const [isLoading, setIsLoading] = useState(false);
    // data context
    const {
        stakeholders,
        allProjects,
        setAlert,
        isDisabled,
        setIsDisabled,
        selectedProject,
        sessionToken
    } = useContext(DataContext)

    // assemble coversheet when generate button is clicked
    function handleClick() {
        setIsLoading(true)
        setIsDisabled(true)
        generateCoverSheet()
    }

    // generate the cover sheet using a template
    function generateCoverSheet() {

        // authenticate with gateway
        const authHeader = {
            withCredentials: true,
            credentials: 'include',
            headers: {'Authorization': `Bearer ${sessionToken}`}
        }

        // get template, replace tags and prompt to download
        /*NOTE: The api gateway checks for the actual file name to download using this URL.
        Be sure to include the specific template name as is appears in the Azure container.
        * */
        fetch('https://qdproxy.services/templates/Cover Sheet Template v2.docx', authHeader)
            .then(res => {
                // get response as binary buffer
                return res.arrayBuffer()
            })
            .then(binary => {
                // zip the binary data
                let zip = new PizZip(binary);

                // create a new docxtemplater object using zipped binary
                let doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // throw error is either project or device is not selected
                if (!selectedProject || !props.inspectionDate) {
                    throw new Error("Please select a project and inspection date before generating a cover sheet.")
                }

                // map stakeholders
                // note: several stakeholders may exist for the same role type
                const allStakeholders = stakeholders.data.map((stakeholder) => {
                    switch (stakeholder.role) {
                        case 'LENDER':
                            return ["lender", stakeholder.vendor.name]
                        case 'BORROWER':
                            return ["borrower", stakeholder.vendor.name]
                        case 'CONTRACTOR':
                            return ["general_contractor", stakeholder.vendor.name]
                        default:
                            return ["none", "none"]
                    }
                })

                // create a unique set of stakeholders
                let unique = [...new Set(allStakeholders)]
                // convert array to object
                unique = Object.fromEntries(allStakeholders)

                // throw error if any required stakeholder is missing
                if (!unique.borrower) {
                    throw new Error("Missing Borrower stakeholder in Rabbet. Please update before generating.")
                }
                if (!unique.lender) {
                    throw new Error("Missing Lender stakeholder in Rabbet. Please update before generating.")
                }
                if (!unique.general_contractor) {
                    throw new Error("Missing General Contractor stakeholder in Rabbet. Please update before generating.")
                }

                // find the selected project from all projects
                const projectDetails = allProjects.data.find(project => project.id === selectedProject)

                // add boilerplate text to the cover sheet depending on user radio button selection
                const boilerplate = `The site is clean, secure and properly maintained. ${props.wasStaffing ? "Subcontractor staffing" : "No subcontractor staffing"} was observed at the time of inspection.`
                const reportInspectionDate = new Date(props.inspectionDate).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})

                // set replacement tas with appropriate data
                doc.setData({
                    inspection_date: reportInspectionDate,
                    orig_compl_date: `${projectDetails.customFields.origdateofcomp}` || "To be determined.",
                    rev_compl_date: `${projectDetails.customFields.revdateofcomp}` || `${projectDetails.customFields.origdateofcomp}` || "To be determined.",
                    lender: unique.lender,
                    borrower: unique.borrower,
                    general_contractor: unique.general_contractor,
                    project_address: `${projectDetails.streetAddress}, ${projectDetails.city}, ${projectDetails.state} ${projectDetails.zip}`,
                    project_manager: projectDetails.customFields.projectManager,
                    project_manager_phone: projectDetails.customFields.projectManagerPhone,
                    project_desc: `${projectDetails.customFields.projectDescription}`,
                    site_conditions: boilerplate
                });

                // replace tags by custom data and generate the document
                doc.render()
                let outputDoc = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });

                // format inspection date to MM.DD.YYYY format for file name
                /*NOTE: the year doesn't show up when prompting for file saving. This most likely
                has to do with the file system truncating the name to avoid unintentionally converting
                to another file type. The year DOES appear in the final file name after saving.
                * */
                let preformat = new Date(props.inspectionDate)
                const month = (preformat.getMonth() + 1).toString().padStart(2, '0');
                const day = preformat.getDate().toString().padStart(2, '0');
                const year = preformat.getFullYear().toString().slice(-2) //return only last two digits of year
                const fileDate = `${month}.${day}.${year}`;

                // prompt user to save document
                saveAs(outputDoc, `${unique.borrower} Inspection Report ${fileDate}.docx`);

                // display success message and recommended file save location
                setIsLoading(false)
                setIsDisabled(false)
                setAlert({
                    message: "Please save this cover sheet in the Processed Reports folder!",
                    severity: "success"
                })

            })
            .catch(error => {
                setIsLoading(false)
                setIsDisabled(false)
                setAlert({
                    message: error.message,
                    severity: "error"
                })
            })
    }

    return(
        <LoadingButton
            onClick={handleClick}
            endIcon={<Send/>}
            loading={isLoading}
            disabled={isDisabled}
            loadingPosition="end"
            variant="contained"
            sx={{
                marginTop: 1,
                width: 175
            }}
        >
            Generate
        </LoadingButton>
    )
}

export default CreateCoverSheet
// react core
import React from 'react';
import {useContext, useEffect, useState} from "react";
// local files
import { DataContext } from "../context/GetProvider";
// 3rd party files
import {Grow, Alert, Container} from "@mui/material";

export default function StatusAlert(props) {

    const { alert, setIsDisabled } = useContext(DataContext)
    const [isDisplayed, setIsDisplayed] = useState(false)

    // disable alert after 5 seconds
    useEffect(() => {
        if (alert.message) {
            setIsDisplayed(true)
            setIsDisabled(true)
            delayDisabled()
        }
    }, [alert])

    function delayDisabled() {
        let alertID
        clearTimeout(alertID)
        alertID = setTimeout(() => {
            setIsDisplayed(false)
            setIsDisabled(false)
        }, 5000)
    }

    if (isDisplayed) {
        return (
            <Container maxWidth={"xs"}>
                <Grow
                    in={isDisplayed}
                >
                    <Alert
                        severity={alert.severity}
                        elevation={3}
                        sx={{marginTop: "15px"}}
                    >
                        <strong>{alert.message}</strong>
                    </Alert>
                </Grow>
            </Container>
        )
    }
    else {
        return null
    }
}
// react core
import React from 'react';
import { useState } from "react";
// local files
import SelectionMenu from "../modules/SelectionMenu";
import AdditionalComments from "../modules/AdditionalComments";
import DispatchDMForm from "./DispatchDMForm";
import StatusAlert from "../modules/StatusAlert";
import GetProvider from "../context/GetProvider";
// 3rd party modules
import {Typography, Stack} from "@mui/material";


export default function DeviceMagicLayout() {

    const [comments, setComments] = useState('')

    return (
        <Stack
            direction="column"
            alignItems="center"
            rowSpacing={1}
            spacing={1}
        >
            <Typography
                variant="caption"
                color="white"
                align="center"
            >
                Please select a project and inspector:
            </Typography>
            <GetProvider>
                <SelectionMenu
                    title={"Project"}
                    type={"project"}
                />
                <SelectionMenu
                    title={"Inspector"}
                    type={"device"}
                />
                <AdditionalComments
                    label={"Additional Comments"}
                    placeholder={"Add anything you'd like the inspector to know"}
                    setComments={setComments}
                />
                <DispatchDMForm comments={comments} />
                <StatusAlert/>
            </GetProvider>
        </Stack>
    )
}
// react core
import React from "react";
// local files
import GetProvider from "../context/GetProvider";
import DispatchesList from "./DispatchesList";
import StatusAlert from "../modules/StatusAlert";
// 3rd party libraries
import { Container, Stack, Typography } from "@mui/material";


export default function DispatchesLayout(props) {

    return (
      <Container maxWidth={"sm"}>
          <Stack
            direction={"column"}
            spacing={1}
          >
              <Typography
                  variant={"caption"}
                  align={"center"}
                  color={"white"}
              >
                  View and sort open inspection requests:
              </Typography>
              <GetProvider>
                  <DispatchesList/>
                  <StatusAlert/>
              </GetProvider>
          </Stack>
      </Container>
  )
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import {ThemeProvider} from "@mui/material";
import AppTheme from "./context/AppTheme";
import {BrowserRouter} from "react-router-dom";
import {Auth0ProviderWithNavigate} from "./auth0-provider-with-navigate";


ReactDOM.render(
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <ThemeProvider theme={AppTheme}>
                    <App />
                </ThemeProvider>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>,
    document.getElementById('root')
);
// react core
import React from 'react';
import { useContext, useEffect, useState } from "react"
// local files
import {DataContext} from "../context/GetProvider"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Stack
} from "@mui/material"
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Paper from '@mui/material/Paper';
// 3rd party files


//conditionally render table cell with clock icon
function isOverdue(dateObj) {

    // Get today's date in milliseconds
    const now = new Date().getTime();

    // Calculate the difference in milliseconds
    const difference = now - dateObj.getTime();

    console.log(dateObj)

    // Convert milliseconds to days
    const daysDifference = Math.round(difference / (1000 * 3600 * 24));

    // date string literal
    const dateStr = dateObj !== "" ? dateObj.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
    }).replace(',', '') : "Please update"

    // if date is greater than 5 days from current date
    if (daysDifference > 5) {
        return(
            <>
                <Stack direction="row">
                    {dateStr}
                    <Box aria-label="clock">
                        <WatchLaterIcon fontSize='small' sx={{pl: 1}}/>
                    </Box>
                </Stack>
            </>
        )
    }

    // else return date without icon
    return(
        <>
            {dateStr}
        </>
    )
}

// dispatches table
export default function DispatchesList() {

    const {
        dmDispatches,
        tpDispatches,
        mondayDispatches,
        setAlert,
        allDevices
    } = useContext(DataContext)

    // table state
    const [rows, setRows] = useState([])

    // device magic items
    useEffect(() => {

        if (dmDispatches) {
            try {
                // device magic dispatches
                const dmDispatchItems = dmDispatches.dispatches.map(dispatch => {
                    const deviceObj = allDevices.devices.find(device => device.id == dispatch.device_id)
                    return ({
                        id: dispatch.id,
                        borrower: dispatch.name,
                        inspector: deviceObj.owner,
                        dateObj: new Date(dispatch.scheduled_at)
                    })
                })

                // update list
                setRows((prevState) => {
                    const uniqueItems = dmDispatchItems.filter((item) => !prevState.includes(item));
                    return [...prevState, ...uniqueItems]
                })
            }
            catch (error) {
                setAlert({
                    message: error.message,
                    severity: "error"
                })
            }
        }
    }, [dmDispatches])

    // truepic items
    useEffect(() => {

        if (tpDispatches) {
            try {
                // truepic dispatches
                let tpDispatchItems = tpDispatches.result.map(dispatch => {
                    return ({
                        id: dispatch.id,
                        borrower: dispatch.consumer_unique_id,
                        inspector: `${dispatch.consumer_first_name} ${dispatch.consumer_last_name}`,
                        dateObj: new Date(dispatch.created_at)
                    })
                })

                // remove undefined values
                tpDispatchItems = tpDispatchItems.filter(item => {
                    return item !== undefined;
                })

                // update list
                setRows((prevState) => {
                    const uniqueItems = tpDispatchItems.filter((item) => !prevState.includes(item));
                    return [...prevState, ...uniqueItems]
                })
            }
            catch (error) {
                setAlert({
                    message: error.message,
                    severity: "error"
                })
            }
        }
    }, [tpDispatches])

    // monday items
    useEffect(() => {
        if (mondayDispatches) {
            try {
                const data = mondayDispatches.data.boards[0].top_group.items_page.items

                let mondayDispatchItems = data.map(dispatch => {

                    // add 1 day to date because of JS being retarded
                    let date = new Date(dispatch.column_values[4].text)
                    date.setDate(date.getDate() + 1)

                    return ({
                        id: dispatch.id,
                        borrower: dispatch.name,
                        inspector: dispatch.column_values[3].display_value,
                        dateObj: date
                    })
                })

                // remove dispatches that contain 'Independent'
                /*Note: any dispatches where the inspector name contains
                'Independent' will already show up via Truepic or Device Magic.
                The filter is designed to eliminate duplicates*/
                mondayDispatchItems = mondayDispatchItems.filter(item => !item.inspector.includes("Independent"))

                // update list
                setRows((prevState) => {
                    const uniqueItems = mondayDispatchItems.filter((item) => !prevState.includes(item));
                    return [...prevState, ...uniqueItems]
                })
            }
            catch (error) {
                setAlert({
                    message: error.message,
                    severity: "error"
                })
            }
        }
    }, [mondayDispatches])

    return (
        <TableContainer component={Paper} sx={{maxHeight: 500}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Borrower/Sponsor</TableCell>
                        <TableCell align="left">Inspector</TableCell>
                        <TableCell align="left">Dispatch Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell
                                component="th"
                                variant="body"
                                scope="row" sx={{maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis"}}>
                                {row.borrower}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.inspector}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {isOverdue(row.dateObj)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
// react core
import React from 'react';
import { useState, createContext, useEffect } from "react";
import {useAuth0} from "@auth0/auth0-react";
// local files
export const DataContext = createContext();

export default function GetProvider(props) {

    // state
    const { getAccessTokenSilently } = useAuth0()
    const [sessionToken, setSessionToken] = useState()
    const [allDevices, setAllDevices] = useState()
    const [allProjects, setAllProjects] = useState()
    const [dmDispatches, setDMDispatches] = useState()
    const [tpDispatches, setTPDispatches] = useState()
    const [mondayDispatches, setMondayDispatches] = useState()
    const [stakeholders, setStakeholders] = useState()
    const [SOV, setSOV] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const [selectedProject, setSelectedProject] = useState()
    const [selectedDevice, setSelectedDevice] = useState()
    const [alert, setAlert] = useState({
        message: "",
        severity: ""
    })

    // constants
    const API = {
        proxyURL: `https://qdproxy.services`,
        projectsEndPoint: `rabbet/projects`,
        devicesEndpoint: `dm/devices`,
        dmDispatchesEndpoint: `dm/dispatches`,
        tpDispatchesEndpoint: 'truepic/requests',
        mondayDispatchesEndpoint: `monday/dispatches`,
        stakeholdersEndpoint: `rabbet/stakeholders`,
        drawsEndpoint: `rabbet/draws`
    }

    // generic fetch
    async function fetchData(url, type, headers) {
        try {
            // fetch devices and wait for response data
            const response = await fetch(url, headers)
            let message

            // set state based on response status
            if (response.ok) {
                return(response.json())
            }
            else {
                switch (type) {
                    case 'projects':
                        message = "Unable to retrieve projects. Please contact admin for help."
                        break
                    case 'devices':
                        message = "Unable to retrieve devices. Please contact admin for help."
                        break
                    case 'dmDispatches':
                        message = "Unable to retrieve current dispatches from Device Magic. Please contact admin for help."
                        break
                    case 'tpDispatches':
                        message = "Unable to retrieve current dispatches from Truepic. Please contact admin for help."
                        break
                    case 'mondayDispatches':
                        message = "Unable to retrieve current dispatches from Monday.com. Please contact admin for help."
                        break
                    case 'sov':
                        message = "Unable to retrieve project SOV. Please contact admin for help."
                        break
                    case 'stakeholders':
                        message = "Unable to retrieve project stakeholders. Please contact admin for help."
                        break
                    default:
                        message = "Case type is not defined for fetch parameters. Please contact admin immediately."
                        break
                }
                throw new Error(message)
            }
        }
        catch(error) {
            throw (error)
        }
    }

    // method for calling and returning access token from Auth0
    async function getAccessToken() {
        try {
            const accessToken = await getAccessTokenSilently();
            setSessionToken(accessToken)
            return(accessToken)

        } catch (error) {
            throw (error)
        }
    }

    // get devices and projects when component mounts
    useEffect(() => {

        // disable UI until web resources resolve
        setIsDisabled(true)

        // get access token from Auth0
        const accessToken = getAccessToken()

        // wait for access token before attempting to fetch from API gateway
        Promise.all([accessToken])
            .then(token => {
                const authHeader = {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${token}`}
                }
                // get device and project information
                const devicesData = fetchData(`${API.proxyURL}/${API.devicesEndpoint}`, 'devices', authHeader)
                const projectsData = fetchData(`${API.proxyURL}/${API.projectsEndPoint}`, 'projects', authHeader)
                const dmDispatchesData = fetchData(`${API.proxyURL}/${API.dmDispatchesEndpoint}`, 'dmDispatches', authHeader)
                const tpDispatchesData = fetchData(`${API.proxyURL}/${API.tpDispatchesEndpoint}`, 'tpDispatches', authHeader)
                const mondayDispatchesData = fetchData(`${API.proxyURL}/${API.mondayDispatchesEndpoint}`, 'mondayDispatches', authHeader)
                Promise.all([
                    devicesData,
                    projectsData,
                    dmDispatchesData,
                    tpDispatchesData,
                    mondayDispatchesData
                ])
                    .then(response => {
                        setAllDevices(() => response[0])
                        setAllProjects(() => response[1])
                        setDMDispatches(() => response[2])
                        setTPDispatches(() => response[3])
                        setMondayDispatches(() =>response[4])
                    })
                    .catch((error) => {
                        setAlert({
                            message: error.message,
                            severity: "error"
                        })
                    })
            })
            .finally(() => {
                setIsDisabled(false)
            })


    }, [API.devicesEndpoint, API.dmDispatchesEndpoint, API.tpDispatchesEndpoint, API.mondayDispatchesEndpoint, API.projectsEndPoint, API.proxyURL])


    // get stakeholders and SOV when selected project changes
    useEffect(() => {

        const authHeader = {
            withCredentials: true,
            credentials: 'include',
            headers: {'Authorization': `Bearer ${sessionToken}`}
        }

        if (selectedProject) {
            setIsDisabled(true)
            const stakeholdersData = fetchData(`${API.proxyURL}/${API.stakeholdersEndpoint}/${selectedProject}`, 'stakeholders', authHeader)
            const sovData = fetchData(`${API.proxyURL}/${API.drawsEndpoint}/${selectedProject}`, 'sov', authHeader)

            Promise.all([
                stakeholdersData,
                sovData
            ])
                .then(response => {
                    setStakeholders(() => response[0])
                    setSOV(() => response[1])
                    if(!response[1].data.length) {
                        setAlert({
                            message: "No draws exist for this project. Dispatch will not contain a SOV.",
                            severity: "warning"
                        })
                    }
                })
                .catch((error) => {
                    setAlert({
                        message: error.message,
                        severity: "error"
                    })
                })
                .finally(() => {
                    setIsDisabled(false)
                })
        }
    }, [API.drawsEndpoint, API.proxyURL, API.stakeholdersEndpoint, selectedProject])

    return (
        <DataContext.Provider value={{
            allDevices,
            allProjects,
            dmDispatches,
            tpDispatches,
            mondayDispatches,
            stakeholders,
            SOV,
            alert,
            setAlert,
            isDisabled,
            setIsDisabled,
            selectedProject,
            setSelectedProject,
            selectedDevice,
            setSelectedDevice,
            sessionToken
        }}>
            {props.children}
        </DataContext.Provider>
    )
}



// react core
import {Route, Routes} from "react-router-dom";
// local files
import ToggleService from "./modules/ToggleServices"
// 3rd party modules
import NotFoundPage from "./pages/NotFoundPage";


export default function App() {

    return(
        <Routes>
            <Route path="/" element={<ToggleService />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}
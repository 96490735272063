// react core
import React from 'react';
import {useContext, useEffect, useState} from 'react';
// local files
import {DataContext} from "../context/GetProvider";
//3rd party
import {
    Divider,
    Typography,
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    ListItemText,
    Select,
    Checkbox,
    Container
} from "@mui/material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 375,
        },
    },
}

function HardCostList(props) {
    const [sovItems, setSovItems] = useState([]);
    const [isSovDisabled, setIsSovDisabled] = useState(true);
    const [siteWalkItems, setSiteWalkItems] = useState([]);
    const [isWalkItemsDisabled, setIsWalkItemsDisabled] = useState(true)
    const [isGenericDisabled, setIsGenericDisabled] = useState(true)
    const {
        SOV,
        setAlert,
        isDisabled,
        allProjects,
        selectedProject
    } = useContext(DataContext)

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        props.selected.setSelectedItems(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // reset the menu items and selected items when items changes
    useEffect(() => {

        if (selectedProject) {
            // map sov items from project
            try {
                if (Object.entries(SOV).length > 0) {
                    const options = SOV.data[0].lineItems.map((option) => {
                        return {
                            value: option.name,
                            key: option.id
                        }
                    })
                    setSovItems(options)
                    setIsSovDisabled(false)
                }
            }
            catch (error) {
                setSovItems([{value: "None specified", key: 3}])
                setIsSovDisabled(true)
                setAlert({
                    message: "No draws have been entered for this project. No SOV inspection items will be available to select.",
                    severity: "warning"
                })
            }
            // map site walk plan items from project details
            try {
                const projectDetails = allProjects.data.find(project => project.id === selectedProject)

                const siteWalkArr = projectDetails.customFields.siteWalkPlan.split(';')

                if (siteWalkArr.length > 0) {
                    const options = siteWalkArr.map((option, index) => {
                        return {
                            value: option,
                            // 2 is added to the index to account for the two static list items above this sub-list
                            key: index + 2
                        }
                    })
                    setSiteWalkItems(options)
                    setIsWalkItemsDisabled(false)
                }
            }
            catch (error) {
                setSiteWalkItems([{value: "None specified", key: 2}])
                setIsWalkItemsDisabled(true)
                setAlert({
                    message: "No Site Walk Plan inspection items were found.",
                    severity: "warning"
                })
            }
            // enable generic list
            setIsGenericDisabled(false)
        }
        // disable list items when no project is selected
        else {
            setIsSovDisabled(true)
            setSovItems([{value: "Select a project", key: 3}])
            setIsWalkItemsDisabled(true)
            setSiteWalkItems([{value: "Select a project", key: 2}])
            setIsGenericDisabled(true)
        }

    }, [SOV, allProjects, selectedProject])

    return (
        <Container maxWidth={"sm"}>
            <FormControl size={"md"} fullWidth>
                <InputLabel id="hard-cost-label" required={true}>Inspection Items</InputLabel>
                <Select
                    labelId="hard-cost-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={props.selected.selectedItems}
                    onChange={handleChange}
                    input={<OutlinedInput label="Inspection Items" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    disabled={isDisabled}
                >
                    <li>
                        <Typography
                            sx={{ mt: 0.5, ml: 2 }}
                            color="text.secondary"
                            display="block"
                            variant="caption"
                        >
                            Generic
                        </Typography>
                    </li>
                    <MenuItem key={0} value={"Site Development"} disabled={isGenericDisabled}>
                        <Checkbox checked={props.selected.selectedItems.indexOf("Site Development") > -1} />
                        <ListItemText primary={"Site Development"} />
                    </MenuItem>
                    <MenuItem key={1} value={"Vertical Improvements"} disabled={isGenericDisabled}>
                        <Checkbox checked={props.selected.selectedItems.indexOf("Vertical Improvements") > -1} />
                        <ListItemText primary={"Vertical Improvements"} />
                    </MenuItem>
                    <Divider component="li" />
                    <li>
                        <Typography
                            sx={{ mt: 0.5, ml: 2 }}
                            color="text.secondary"
                            display="block"
                            variant="caption"
                        >
                            Site Walk Plan
                        </Typography>
                    </li>
                    {siteWalkItems.map((item) => (
                        <MenuItem key={item.key} value={item.value} disabled={isWalkItemsDisabled}>
                            <Checkbox checked={props.selected.selectedItems.indexOf(item.value) > -1} />
                            <ListItemText primary={item.value} />
                        </MenuItem>
                    ))}
                    <Divider component="li" />
                    <li>
                        <Typography
                            sx={{ mt: 0.5, ml: 2 }}
                            color="text.secondary"
                            display="block"
                            variant="caption"
                        >
                            SOV
                        </Typography>
                    </li>
                    {sovItems.map((item) => (
                        <MenuItem key={item.key} value={item.value} disabled={isSovDisabled}>
                            <Checkbox checked={props.selected.selectedItems.indexOf(item.value) > -1} />
                            <ListItemText primary={item.value} />
                        </MenuItem>
                    ))}
                    <Divider component="li" />
                </Select>
            </FormControl>
        </Container>
    )
}

export default HardCostList;